@font-face {
  font-family: "GrandRegular";
  src: url('../fonts/grandregular.woff2') format('woff2'), url('../fonts/grandregular.woff') format('woff');
}

@mixin font-roboto-regular {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal;
}

@mixin font-roboto-italic {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: italic;
}

@mixin font-roboto-bold {
  font-family: 'Roboto';
  font-weight: bold;
}

@mixin font-roboto-bold-italic {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: italic;
}

@mixin font-ntf-grand () {
  font-family: 'GrandRegular';
  letter-spacing: 0.03rem;
}